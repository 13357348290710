@import url('https://fonts.googleapis.com/css2?family=Playball&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

.profile_page_fixed{
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #fff;
  overflow: auto;
  display: flex;
  font-family: 'Open Sans', sans-serif;
}

.profile_page_fixed_wrap{
  overflow: hidden;
  width: 100%;
  height: max-content;
  min-height: 100%;
  display: flex;
  align-items: stretch;
  position: relative;
}

.profile_page_fixed_wrap:before{
  content: '';
  display: block;
  width: 16px;
  height: 45%;
  background-image: url(../../img/lnd20Profile/stripes_blue.svg);
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  right: calc(50%);
  transform: translateX(-100%);
}

.profile_page_fixed_wrap:after{
  content: '';
  display: block;
  width: 16px;
  height: 40%;
  background-image: url(../../img/lnd20Profile/stripes_white.svg);
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(100%);
}

.profile_page_left{
  //min-height: 100%;
  //height: max-content;
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 55px 0 38px;
}

.profile_page_name{
  font-family: 'Playball', cursive;
  font-size: 48px;
  color: #2A497E;
  margin: 0 0 60px 68px;
}

.profile_page_content{
  margin: auto 40px auto 136px;
  max-width: 549px;
}

.profile_page_title{
  margin-bottom: 32px;
  font-weight: 600;
  font-size: 36px;
  color: #333;
}

.profile_page_title span{
  font-weight: bold;
}

.profile_page_text{
  margin-bottom: 43px;
}

.profile_page_text p{
  font-weight: 600;
  font-size: 18px;
  line-height: 1.5;
  color: #333333;
  margin-bottom: 0;
}

.profile_page_text p + p{
  margin-top: 13px;
}

.profile_page_text p span{
  color: #4267B2;
}

.profile_page_get_btn{
  width: 307px;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4267B2;
  border: 1px solid #2A497E;
  border-radius: 4px;
  font-weight: 600;
  font-size: 20px;
  color: #FFFFFF;
  cursor: pointer;
}

.profile_page_get_btn img{
  margin-right: 16px;
}

.profile_page_photos_wrap{
  max-width: 200%;
  overflow: hidden;
  margin-top: 60px;
  padding: 30px 0 30px 136px;
  display: flex;
  align-items: center;
  width: max-content;
  position: relative;
  z-index: 2;
}

.profile_page_photos_text{
  flex: none;
  margin-right: 57px;
}

.profile_page_photos_title{
  font-weight: 600;
  font-size: 36px;
  color: #333333;
  margin-bottom: 16px;
}

.profile_page_photos_all_link{
  font-weight: 600;
  font-size: 18px;
  color: #4267B2;
  cursor: pointer;
}

.profile_page_photos_list{
  display: flex;
  flex: none;
}

.profile_page_photos_list img{
  width: 321px;
  height: 241px;
  border-radius: 3px;
  box-shadow: 0 7px 25px rgba(255, 255, 255, 0.4);
  cursor: pointer;
  object-fit: cover;
  object-position: top;
}

.profile_page_photos_list img + img{
  margin-left: 26px;
}

.profile_page_right{
  width: 50%;
  background-size: cover;
  background-position: center top;
}

.profile_page_modal{
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;

  background-color: rgba(55, 78, 108, 0.32);
  backdrop-filter: blur(4px);
  z-index: 999;
}

.profile_page_modal_image{
  display: block;
  margin: auto;
  max-width: calc(100% - 20px);
  max-height: calc(100% - 20px);
}

.profile_page_modal_arrow{
  width: 70px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  background: rgba(0, 50, 101, 0.3);
}

.profile_page_modal_arrow.prev{
  left: 0;
  border-radius: 0 5px 5px 0;
}

.profile_page_modal_arrow.next{
  right: 0;
  border-radius: 5px 0 0 5px;
}

.profile_page_modal_arrow img{
  width: 30px;
  display: block;
  cursor: pointer;
}

.profile_page_modal_close{
  position: absolute;
  right: 22px;
  top: 22px;
  cursor: pointer;
  padding: 10px;
}

.profile_page_modal_close img{
  width: 32px;
}

@media screen  and (max-width: 1499px){
  .profile_page_left{
    padding-left: 81px;
    padding-bottom: 0;
  }

  .profile_page_name{
    margin: 0 0 60px 0;
    font-size: 36px;
  }

  .profile_page_content{
    max-width: 414px;
    margin-left: 0;
  }

  .profile_page_title{
    font-size: 28px;
    margin-bottom: 29px;
  }

  .profile_page_text p{
    font-size: 14px;
  }

  .profile_page_get_btn{
    width: 241px;
    height: 56px;
    font-size: 16px;
  }

  .profile_page_get_btn img{
    width: 20px;
  }

  .profile_page_photos_wrap{
    padding-left: 0;
    max-width: unset;
  }

  .profile_page_photos_title{
    font-size: 28px;
  }

  .profile_page_photos_all_link{
    font-size: 14px;
  }

  .profile_page_photos_list img{
    width: 173px;
    height: 130px;
    object-fit: cover;
  }
}

@media screen  and (max-width: 1099px){
  .profile_page_fixed_wrap{
    flex-direction: column;
    height: 100%;
    min-height: 650px;
    position: relative;
  }

  .profile_page_fixed_wrap:before,
  .profile_page_fixed_wrap:after{
    display: none;
  }

  .profile_page_left{
    padding: 40px 49px 0 54px;
    width: 100%;
    height: max-content;
    z-index: 2;
  }

  .profile_page_name{
    margin-left: auto;
    margin-bottom: -20px;
    width: max-content;
  }

  .profile_page_text{
    margin-bottom: 6px;
  }

  .profile_page_get_btn{
    transform: translateY(50%);
  }

  .profile_page_photos_wrap{
    position: absolute;
    left: 30px;
    bottom: 0;
  }

  .profile_page_right{
    position: relative;
  }

  .profile_page_right:before{
    content: '';
    display: block;
    width: 40%;
    height: 18px;
    background-image: url(../../img/lnd20Profile/stripes_blue_mob.svg);
    background-size: contain;
    background-position: left center;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 215px;
    left: 0;
  }

  .profile_page_right:after{
    content: '';
    display: block;
    width: 40%;
    height: 18px;
    background-image: url(../../img/lnd20Profile/stripes_white_mob.svg);
    background-size: contain;
    background-position: right center;
    background-repeat: no-repeat;
    position: absolute;
    top: 25px;
    right: 0;
  }

  .profile_page_photos_text{
    display: none;
  }

  .profile_page_right{
    width: 100%;
    flex: 1;
  }

  .profile_page_photos_list img{
    width: 198px;
    height: 149px;
  }

  .profile_page_modal_arrow{
    width: 50px;
    height: 85px;
  }

  .profile_page_modal_arrow img{
    width: 20px;
  }

  .profile_page_modal_close{
    right: 10px;
    top: 10px;
  }

  .profile_page_modal_close img{
    width: 22px;
  }
}

@media screen  and (max-width: 599px){
  .profile_page_fixed_wrap{
    min-height: 470px;
  }

  .profile_page_name{
    display: none;
  }

  .profile_page_left{
    padding: 38px 20px 0;
  }

  .profile_page_title{
    font-size: 22px;
    margin-bottom: 13px;
  }

  .profile_page_content{
    margin-right: 0;
    max-width: unset;
  }

  .profile_page_get_btn{
    width: 280px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .profile_page_text p{
    font-size: 12px;
  }

  .profile_page_photos_list img{
    width: 76px;
    height: 56px;
  }

  .profile_page_photos_list img + img{
    margin-left: 7px;
  }

  .profile_page_right:before,
  .profile_page_right:after{
    display: none;
  }
}